//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BadRequestResultTypeDto { 
	MembershipNotActive = "MembershipNotActive", 
	NoMembership = "NoMembership", 
	NoTibetPublication = "NoTibetPublication", 
	NoLicenseInTibet = "NoLicenseInTibet", 
	CompanyUserNotAllowed = "CompanyUserNotAllowed", 
	UserRejectedInvitation = "UserRejectedInvitation"
}
