import { makeObservable, observable } from "mobx";
import {
  CourseTestContext,
  ExamTestContext,
  ModuleTestContext,
  TestContext,
} from "../../../../application/pages/shared/Test/TestViewModel";
import { HttpClient } from "../../../../infrastructure/api/HttpClient";
import { GetTaskPreviewRequest } from "../../../../infrastructure/types/api/test/GetTaskPreviewRequest";
import { GetTestRequest } from "../../../../infrastructure/types/api/test/GetTestRequest";
import { LockExamRequest } from "../../../../infrastructure/types/api/test/LockExamRequest";
import { SubmitCourseTestRequest } from "../../../../infrastructure/types/api/test/SubmitCourseTestRequest";
import { SubmitExamTestRequest } from "../../../../infrastructure/types/api/test/SubmitExamTestRequest";
import { SubmitModuleTestRequest } from "../../../../infrastructure/types/api/test/SubmitModuleTestRequest";
import { CourseContentId } from "../../../../infrastructure/types/content/model/CourseContentId";
import { TaskTypeDto } from "../../../../infrastructure/types/test/dto/TaskTypeDto";
import { UserTaskAnswerDto } from "../../../../infrastructure/types/test/dto/UserTaskAnswerDto";
import { TaskWithStatus } from "../../models/TaskWithStatus";
import { Test } from "../../models/Test";

export class TestStore {
  @observable tests: Test[] = [];

  // Task type mapping, could be moved somewhere else

  taskTypeMapping: { [key in TaskTypeDto]: number } = {
    Choice: 0,
    Match: 1,
    Gap: 2,
    Input: 3,
  };

  constructor(private httpClient: HttpClient) {
    makeObservable(this);
  }

  async submitTestAsync(userTaskAnswers: UserTaskAnswerDto[], context: TestContext) {
    switch (context._type) {
      case "course":
        return this.submitCourseTest(userTaskAnswers, context);
      case "module":
        return this.submitModuleTest(userTaskAnswers, context);
      case "exam":
        return this.submitExamTest(userTaskAnswers, context);
      default:
        throw new Error("Exam doesn't have implemented submit method yet");
    }
  }

  async selectTest(id: string) {
    const foundTest = this.tests.find(c => c.id.value === id);

    if (foundTest) {
      return foundTest;
    }

    const loadedTest = await this.loadTest(id);
    return loadedTest;
  }

  async loadTask(id: string, revision: string, taskType: TaskTypeDto) {
    const taskTypeNumber = this.taskTypeMapping[taskType];
    const getTaskResponse = await this.httpClient.send(
      new GetTaskPreviewRequest({ id, revision, taskType: taskTypeNumber })
    );
    return new TaskWithStatus(getTaskResponse);
  }

  async lockExam(courseAssignmentId: string, courseContentId: CourseContentId) {
    await this.httpClient.send(new LockExamRequest({ courseAssignmentId, courseContentId }));
  }

  private async submitCourseTest(userTaskAnswers: UserTaskAnswerDto[], context: CourseTestContext) {
    const contextRequestParams = {
      courseContentId: context.courseContentId,
      courseAssignmentId: context.courseAssignmentId,
      companyId: context.companyId.value,
    };
    const requestData = {
      userTaskAnswers,
      ...contextRequestParams,
    };
    const testResult = await this.httpClient.send(new SubmitCourseTestRequest(requestData));
    return testResult;
  }

  private async submitModuleTest(userTaskAnswers: UserTaskAnswerDto[], context: ModuleTestContext) {
    const contextRequestParams = {
      courseContentId: context.courseContentId,
      moduleContentId: context.moduleContentId,
      courseAssignmentId: context.courseAssignmentId,
      companyId: context.companyId.value,
    };
    const requestData = {
      userTaskAnswers,
      ...contextRequestParams,
    };
    const testResult = await this.httpClient.send(new SubmitModuleTestRequest(requestData));
    return testResult;
  }

  private async submitExamTest(userTaskAnswers: UserTaskAnswerDto[], context: ExamTestContext) {
    const contextRequestParams = {
      courseContentId: context.courseContentId,
      courseAssignmentId: context.courseAssignmentId,
    };
    const requestData = {
      userTaskAnswers,
      ...contextRequestParams,
    };
    const testResult = await this.httpClient.send(new SubmitExamTestRequest(requestData));
    return testResult;
  }

  private async loadTest(id: string) {
    const getTestResponse = await this.httpClient.send(new GetTestRequest({ id }));
    const loadedTest = new Test(getTestResponse);
    this.tests.push(loadedTest);
    return loadedTest;
  }
}
