//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CourseAssignmentForGroupAndUserDto } from '../../courseassignments/dto/CourseAssignmentForGroupAndUserDto';
import { PagedResultDto } from '../../shared/dto/PagedResultDto';
import { IApiRequest } from '../../IApiRequest';

export class GetCourseAssignmentsRequest extends IApiRequest<PagedResultDto<CourseAssignmentForGroupAndUserDto>>
{
	public $type = 'GetCourseAssignmentsRequest';
	constructor(public data: { companyId: string, courseId: string, skip: number, take: number }) { super(); }
}
